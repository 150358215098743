import React from 'react';
import RefreshIcon from "@mui/icons-material/Refresh";
import { Tooltip, Button, useTheme, CircularProgress } from "@mui/material";
import { useRefresh } from '../../context/PageRefreshContext';

const PageRefresh = () => {
    const theme = useTheme();
    const { refresh, isPageRefresh } = useRefresh();

  return (
    <>
    <div className="DownloadReport">
      <Tooltip title="Refresh Data">
      <Button
        variant="outlined"
        onClick={refresh} 
        color="primary"
        size="large"
        startIcon={isPageRefresh ? <CircularProgress size={24} /> : <RefreshIcon fontSize="large" />}
        disabled={isPageRefresh}
        sx={{
          color: theme.palette.textcolors.headerplaningitemtext,
          border: theme.palette.borderClr.reportDownload,
          backgroundColor: theme.palette.background.reportDownload,
          height:'100%'
        }}
      >
        Refresh
      </Button>
      </Tooltip>
      </div>
    </>
  );
};

export default PageRefresh;
