import React from 'react';
import { CssBaseline } from '@mui/material';
import AppRoutes from './routes/AppRoutes';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './api/queryClient';
import { ToastProvider } from './context/ToastContext';
import { DateRangeProvider } from './context/DateRangeContext';
import { FilterProvider } from './context/FilterContext';
import { ColorContextProvider } from "./context/themeProvider.js";
import { RefreshProvider } from './context/PageRefreshContext.jsx';
import "./App.css";

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ColorContextProvider>
      <ToastProvider>
      <RefreshProvider>
        <DateRangeProvider>
          <FilterProvider>
            <CssBaseline />
            <AppRoutes />
          </FilterProvider>
        </DateRangeProvider>
      </RefreshProvider>
      </ToastProvider>
    </ColorContextProvider>
  </QueryClientProvider>
);

export default App; 
