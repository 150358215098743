import React, { createContext, useContext, useState, useCallback } from "react";

const RefreshContext = createContext(undefined);

export const RefreshProvider = ({ children }) => {
  const [isPageRefresh, setIsPageRefresh] = useState(false);

  const refresh = useCallback(async () => {
    setIsPageRefresh(true);
    try {
      console.log("Calling API to refresh...");
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("API refreshed successfully!");
    } catch (error) {
      console.error("Refresh failed", error);
    } finally {
       setIsPageRefresh(false);
    }
  }, []);

  return (
    <RefreshContext.Provider value={{ refresh, isPageRefresh }}>
      {children}
    </RefreshContext.Provider>
  );
};

export const useRefresh = () => {
  const context = useContext(RefreshContext);
  if (!context) {
    throw new Error("useRefresh must be used within a RefreshProvider");
  }
  return context;
};
