const OperatorMapping = {
  '<': 'lt',
  '<=': 'lte',
  '>': 'gt',
  '>=': 'gte',
  '=': 'eq',
  '!=': 'ne',
  // String Operators
  'contains': 'iLike',
  'notContain': 'notILike',
  'startsWith': 'startsWith',
  'endsWith': 'endsWith',
  'isEmpty': 'isEmpty',
  'isNotEmpty': 'isNotEmpty',
  'equals': 'eq',
  'isAnyOf': 'in',
  };
  
export const getFormattedFilters = (filters) => {
    return Object.entries(filters).reduce((acc, [key, value]) => {
        if (value.length > 0) {
            acc[key] = value.join(',');
        }
        return acc;
    }, {});
};


export const createFilterRequestData = (data) => {
    // console.log("data ",data);
    const { items, logicOperator } = data;
    if(!Array.isArray(items)){
      return [];
    }
    const logic = items.length === 1 
    ? 'AND' 
    : logicOperator && typeof logicOperator === 'string' && logicOperator.trim() 
      ? logicOperator.trim().toUpperCase() 
      : 'AND';
    return items
      .filter((i) => (i.hasOwnProperty('value') && i.value) || i.operator === 'isEmpty' || i.operator === 'isNotEmpty')
      .map((i) => ({
        column: i.field,
        operator: OperatorMapping[i.operator], 
        value: i.value,
        logic
      }));
  };